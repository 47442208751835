const production = true;
const local = !production;

const globals = {
    context : {
        user_connected:false
    },
    links:{
        presse_partenaire:"https://the-blog.fr/",
        blog_thcc:"https://www.hotels-community.info/",
        contact:"https://marketplace-hotel.com/#/contact",
        partenaires_externes:"https://www.thcc-community.com/expertsetpartenaires",
        forum_blog:"http://www.coronavirus-hotels.info/",
        toolbox:"http://www.toolbox-thcc.com/",
        events:"https://www.coronavirus-hotels.info/conferences-en-ligne-events",
        adhesion_hotels:"https://www.thcc-community.com/copiedehoteliers",
        linked_in:"https://www.linkedin.com/company/thcc-hoteliers-for-hoteliers/?viewAsMember=true",
        facebook:"https://www.facebook.com/THCC.EMEA/",
        youtube:"https://youtu.be/UmuEYczluME"

    },
    parameters : {
                    debugging : true, 
                    debugging_state : local,
                    bypass_token:false, 
                    admin_bypass_email:"osteuermann@thcc-eu.com",
                    local:local, 
                    linguee_search_enabled: false, 
                    rich_edit_enabled:true},
    status:{disconnected:0, admin_connected:1, partner_connected:2},
    admin_partner_view_state : {log_state:0, admin_state:1, lounge_state:2, news_edit_state:3, partner_edit_state:4},
    website:{
                title:"the market place",
                slogan:"the market place by thcc, la centrale d'achats spécialisée dans l'hôtellerie"
            },
    mailer:{
        ne_pas_repondre_email : "ne-pas-repondre@marketplace-hotel.com",
        admin_target_email : "thcc_contact_group@marketplace-hotel.com",
        signature:"Marketplace-hotel"
    },
    mailer_service : {
        ...(local && {url : 'http://localhost:4404'}),
        ...(production && {url : 'https://thcc.mailer.neuronal.bible'})
    },
    category_list_endpoint : {
        ...(local && {url : 'http://localhost:4001/category-list'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/category-list'})
    },
    category_distinct_list_endpoint : {
        ...(local && {url : 'http://localhost:4001/categories-distinct'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/categories-distinct'})
    },

    partner_list_endpoint : {
        ...(local && {url : 'http://localhost:4001/partner-list'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partner-list'})
    },

    partner_load_endpoint : {
        ...(local && {url : 'http://localhost:4001/partner-load'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partner-load'})
    },

    offers_load_endpoint : {
        ...(local && {url : 'http://localhost:4001/offers-load'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/offers-load'})
    },

    gallery_load_endpoint : {
        ...(local && {url : 'http://localhost:4001/gallery-load'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/gallery-load'})
    },

    partner_delete_endpoint : {
        ...(local && {url : 'http://localhost:4001/partner-delete'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partner-delete'})
    },

    partners_page_endpoint : {
        ...(local && {url : 'http://localhost:4001/partners-page'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partners-page'})
    },

    partners_page_categories_buttons_endpoint : {
        ...(local && {url : 'http://localhost:4001/partners-page-categories-buttons'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partners-page-categories-buttons'})
    },

    check_operator_code_endpoint : {
        ...(local && {url : 'http://localhost:4001/check-operator-code'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/check-operator-code'})
    },

    req_add_proc_endpoint : {
        ...(local && {url : 'http://localhost:4001/req-add-proc'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/req-add-proc'})
    },

    partner_offers_gallery_add_endpoint : {
        ...(local && {url : 'http://localhost:4001/partner-offers-gallery-add'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partner-offers-gallery-add'})
    },

    partner_offers_gallery_update_endpoint : {
        ...(local && {url : 'http://localhost:4001/partner-offers-gallery-update'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/partner-offers-gallery-update'})
    },

    offers_types_list_endpoint : {
        ...(local && {url : 'http://localhost:4001/offers-types-list'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/offers-types-list'})
    },

    news_endpoint : {
        ...(local && {url : 'http://localhost:4001/news'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/news'})
    },

    news_update_endpoint : {
        ...(local && {url : 'http://localhost:4001/news-update'}),
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/news-update'})
    },

    jlrpt_file_add_endpoint : {
        ...(local && {url : 'http://localhost:4001/jlrpt-file-add'}),
        //...(production && {url : 'https://media-service.jesusestlareponsepourtous.org/file-add'})
        ...(production && {url : 'https://thcc-service-prod.neuronal.bible/jlrpt-file-add'}) //DEBUGGIN on THCC service prod
    },
}

export {globals}