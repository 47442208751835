import React, { Component } from 'react'
import { NavLink} from 'react-router-dom';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import StickyMenu from '../StickyMenu/StickyMenu'
import PartnersGrid from '../PartnersGrid/PartnersGrid'
import '../HomePage/HomePage.css'
import NestedTitlePicture from '../NestedTitlePicture/NestedTitlePicture'
import mainBristol from '../../Assets/img/main-bristol.jpeg'
import foodCategory from '../../Assets/img/category/food_and_beverage.jpg'
import marketingCategory from '../../Assets/img/category/marketing_and_guest_experience.jpg'
import meetingsCategory from '../../Assets/img/category/meetings_and_events.jpg'
import operationsCategory from '../../Assets/img/category/operations.jpg'
import newsPicture from '../../Assets/img/last_news.jpg'
import NewsAdmin from '../Admin/NewsAdmin/NewsAdmin';

class WhoPage extends Component{
    constructor(props){
        super(props)
    }

    render = () => {
        let container_width = "100%"
        let container_height = ""
        let containter_font_size = "3.0vw"
        
        return(
            <div className="who-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <NestedTitlePicture
                        height={"200px"} 
                        pictureTitle={"Qui sommes nous ?"}
                        pictureURL={mainBristol}
                        fontSize={"x-larger"}
                    />
                </div>
                <div className="body-page-container">
                    {/* Welcome Panel 
                    <div className="welcome-title">
                        <div>
                            Bienvenue au MarketPlace THCC !
                        </div>
                    </div>*/}

                    {/* Blabla laïus */}
                    <div className="introduction-container">
                        {/*
                        <div className="thcc-marketplace-intro">
                            Notre centrale d’achats et de référencement, propose à plus de 700 établissements adhérents les meilleurs prix négociés auprès de plus d’une centaine de fournisseurs dans de nombreux domaines.
                        </div>*/}
                        <div className="introduction-columns-container">
                            <div className="introduction-column">
                                <div className="intro-title">Le mot du président</div>
                                <p>Depuis 2015 <span className="thcc-emphasis">THCC</span> n’a de cesse de faire bouger l’hôtellerie indépendante. Nous sommes un club & une communauté d'hôteliers propriétaires et décideurs francophones indépendants. (<span className="thcc-emphasis">THCC</span> n'est ni une chaîne/groupe hôtelier, ni une marque, et encore moins une société de conseils.)
                                Dès 2021, <span className="thcc-emphasis">THCC</span> change d’image et devient le Club Hôtelier par <span className="thcc-emphasis">THCC</span> comptant près de 632 Hôteliers membres répartis dans les zones francophones du globe et décisionnaires sur près de 1700 établissements résidants principalement en France, Suisse, Benelux, Afrique, Québec, etc.</p>
                                <p>
                                Nous ne sommes pas une chaîne hôtelière mais une communauté et un club : la différence&nbsp;? </p>
                                <p>
                                Les groupes hôteliers ont une image et une stratégie propre, l’hôtelier adhère directement à cette stratégie et aux standards de la marque en devenant membres du fait de l’apport de la marque/groupe. Chez <span className="thcc-emphasis">THCC</span> l’identité de chacun des hôtels et de nos membres est prioritaire, elle passe devant la nôtre. D’ailleurs, nous sommes partenaires de plusieurs marques hôtelières, puisqu’un nombre important de leurs adhérents sont également membres de notre communauté. Nous sommes complémentaires aux marques/groupes dans nos actions et objectifs qui sont définis en 3 axes :
                                <ol>
                                <li>Partager et échanger entre professionnels</li>
                                <li>Booster l’identité propre de nos membres et de leurs hôtels </li>
                                <li>Aider nos membres à optimiser leur résultat</li></ol>
                                Ceci grâce à :
                                <ul>
                                <li>des contacts privilégiés entre hôteliers (ex: événements)</li>
                                <li>des outils technologiques pour communiquer entre nous confidentiellement.</li>
                                <li>des avantages concrets obtenus pour nos membres (rabais et discounts)</li>
                                <li>des actions et services personnalisées pour nos membres dans les segments suivants :</li>
                                <ul>
                                <li>Finance (recherche de capitaux, levée de fond, assurances, négociation des dettes etc)</li>
                                <li>Achat/vente d’hôtels off market (d’hôtelier à hôtelier)</li>
                                <li>Sales & Marketing / Distribution </li>
                                <li>Management/Opération & RH Recrutement</li></ul></ul></p>
                            </div>
                        </div>
                    </div>

                    {/* Partners */}
                    <div className="partners-fluid-container">
                        <div className="partners-container">
                            <div className="partners-title">Partenaires</div>
                            <p className="partners-introduction">
                                Retrouvez l’ensemble des partenaires référencés, tous sélectionnés et pertinents pour des exploitants et des investisseurs de CHR. Classés par grande famille de services, de produits, ils vous proposent des conditions privilégiées en tant qu’adhérent.
                            </p>

                            <NavLink to="/browsepartners">
                                <Button renderAs="button">
                                    <span>Voir tous les partenaires</span>
                                </Button>
                            </NavLink>

                            <PartnersGrid limit={3}/>
                        </div>
                    </div>


                    {/* News */}
                    <div className="news-fluid-container">
                        <div className="news-container">
                            {/*
                            <div className="link-with-arrow">Voir toutes les news</div>*/}
                            {/* Latest news */}
                            <div className="news-columns-container">
                               
                                <div className="news-column">
                                    <NestedTitlePicture
                                        width={"100%"} 
                                        height={"100%"} 
                                        pictureTitle={"Dernière news !"}
                                        pictureURL={newsPicture}
                                        fontSize={"x-larger"}
                                    />
                                </div>
                                <div class="news-column">
                                    <div className="latest-news-teaser">Dernière news</div>
                                    <NewsAdmin
                                        readOnly={true}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Categories */}
                    <div className="categories-fluid-container">
                        <div className="categories-container">
                            <div className="categories-title">
                                Nos catégories
                            </div>

                            {/* Categories grid */}
                            <div className="categories-grid-cells">
                                <div className="category-cell">
                                    <NestedTitlePicture
                                        width={container_width}
                                        height={container_height} 
                                        pictureTitle={"Opérations"}
                                        pictureURL={operationsCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>Techonologies, Opérations</p>
                                </div>
                                <div className="category-cell">
                                    <NestedTitlePicture
                                        width={container_width}
                                        height={container_height}
                                        pictureTitle={"Marketing"}
                                        pictureURL={marketingCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>	Marketing & guest experience</p>
                                </div>
                                <div className="category-cell">
                                    <NestedTitlePicture
                                        width={container_width}
                                        height={container_height} 
                                        pictureTitle={"Meetings & Events"}
                                        pictureURL={meetingsCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>Meetings & Events</p>
                                </div>
                                <div className="category-cell">
                                    <NestedTitlePicture
                                       width={container_width}
                                       height={container_height} 
                                        pictureTitle={"Food & Beverage"}
                                        pictureURL={foodCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>Food & Beverage</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Transparent Jumbo */}
                </div>
            </div>
        )
    }
} 

export default WhoPage;