import React, { Component } from 'react'
import { NavLink} from 'react-router-dom';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import StickyMenu from '../StickyMenu/StickyMenu'
import PartnersGrid from '../PartnersGrid/PartnersGrid'
import './HomePage.css'
import NestedTitlePicture from '../NestedTitlePicture/NestedTitlePicture'
import mainBristol from '../../Assets/img/main-bristol.jpeg'
import foodCategory from '../../Assets/img/category/food_and_beverage.jpg'
import marketingCategory from '../../Assets/img/category/marketing_and_guest_experience.jpg'
import meetingsCategory from '../../Assets/img/category/meetings_and_events.jpg'
import operationsCategory from '../../Assets/img/category/operations.jpg'
import { globals } from '../Globals/Globals';
import NewsAdmin from '../Admin/NewsAdmin/NewsAdmin';

class HomePage extends Component{
    constructor(props){
        super(props)
    }

    render = () => {
        let container_width = "100%"
        let container_height = ""
        let containter_font_size = "3.0vw"
        
        return(
            <div className="main-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <NestedTitlePicture
                        height={"200px"} 
                        pictureTitle={globals.website.slogan}
                        pictureURL={mainBristol}
                        fontSize={"x-larger"}
                    />
                </div>
                {/* Sticky menu bar */}
                <StickyMenu/>
                <div className="body-page-container">
                    {/* Welcome Panel */}
                    <div className="welcome-title">
                        <div>
                            Bienvenue au MarketPlace THCC !
                        </div>
                    </div>

                    {/* Blabla laïus */}
                    <div className="premium-and-top-news-container">
                        {/* Premium partners */}
                        <div className="premium-container">
                            <div className="premium-teaser">Partenaires Prémium</div>
                            <PartnersGrid 
                                limit={6}
                                premium={true}
                                />
                        </div>

                        {/* Top News */}
                        <div className="top-news-container">
                            {/* Latest news */}
                            <div className="news-columns-container">   
                                <div class="news-column">
                                    <div className="latest-news-teaser">Dernière news</div>
                                    <NewsAdmin
                                        readOnly={true}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Partners */}
                    <div className="partners-fluid-container">
                        <div className="partners-container">
                            <div className="partners-title">Partenaires</div>
                            <p className="partners-introduction">
                                Retrouvez l’ensemble des partenaires référencés, tous sélectionnés et pertinents pour des exploitants et des investisseurs de CHR. Classés par grande famille de services, de produits, ils vous proposent des conditions privilégiées en tant qu’adhérent.
                            </p>

                            <NavLink to="/browsepartners">
                                <Button renderAs="button">
                                    <span>Voir tous les partenaires</span>
                                </Button>
                            </NavLink>

                            <PartnersGrid 
                                limit={3}
                                premium={false}
                            />
                        </div>
                    </div>

                    {/* Categories */}
                    <div className="categories-fluid-container">
                        <div className="categories-container">
                            <div className="categories-title">
                                Nos catégories
                            </div>

                            {/* Categories grid */}
                            <div className="categories-grid-cells">
                                <div className="category-cell">
                                    <NestedTitlePicture
                                        width={container_width}
                                        height={container_height} 
                                        pictureTitle={"Opérations"}
                                        pictureURL={operationsCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>Techonologies, Opérations</p>
                                </div>
                                <div className="category-cell">
                                    <NestedTitlePicture
                                        width={container_width}
                                        height={container_height}
                                        pictureTitle={"Marketing"}
                                        pictureURL={marketingCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>	Marketing & guest experience</p>
                                </div>
                                <div className="category-cell">
                                    <NestedTitlePicture
                                        width={container_width}
                                        height={container_height} 
                                        pictureTitle={"Meetings & Events"}
                                        pictureURL={meetingsCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>Meetings & Events</p>
                                </div>
                                <div className="category-cell">
                                    <NestedTitlePicture
                                       width={container_width}
                                       height={container_height} 
                                        pictureTitle={"Food & Beverage"}
                                        pictureURL={foodCategory}
                                        fontSize={containter_font_size}
                                    />
                                    <p>Food & Beverage</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Transparent Jumbo */}
                </div>
            </div>
        )
    }
} 

export default HomePage;