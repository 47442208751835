import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { Button, Input } from "reactstrap";
import PartnersGrid from "../Components/PartnersGrid/PartnersGrid.js";
import PartnersGrid2 from "../Components/PartnersGrid/PartnersGrid2.js";
import PartnerSearch from "../Components/PartnerSearch/PartnerSearch";
import { globals } from "../Components/Globals/Globals.js";

const BrowsePartnersView = () => {
    const [buttonsFilter, setButtonsFilter] = useState(null);
    const [filter, setFilter] = useState(null);
    const [partner, setPartner] = useState(null);
    let history = useHistory();

    return(
        <>
            <div className="browse-partners-page">
                <div className="page-title">Consulter nos partenaires</div>
                <div className="page-introduction">Retrouvez l’ensemble des partenaires reférencés, tous sélectionnés et pertinents pour des exploitants et des investisseurs de CHR. Classés par grande famille de services, de produits, ils vous proposent des conditions privilégiées en tant qu’adhérent.</div>

                <PartnerSearch
                    placeholder= {"Filtrer les partenaires par catégories..."}
                    onButtonsFilterChange = {(inClause)=>{setButtonsFilter(inClause)}}
                    onInputFilterChange = {(newFilter) => {setFilter(newFilter);}}
                    disable_overlay= {true}
                    />
                {/* Partners browser*/}
                <div className="partners-fluid-container">
                    {globals.parameters.linguee_search_enabled &&
                    <div className="partners-container">
                        <PartnersGrid 
                            categoryFilter={filter}
                            onPartnerClickHandler={(partnerItem)=>{history.push(`/userlounge/${partnerItem}`);setPartner(JSON.stringify(partnerItem));}}/>
                    </div>}

                    <div className="partners-container">
                        <PartnersGrid2 
                            categoriesButtonsFilter={buttonsFilter}
                            onPartnerClickHandler_DISABLED={(partnerItem)=>{setPartner(JSON.stringify(partnerItem));}}
                            onPartnerClickHandler={(partnerItem)=>{console.clear(); console.log("partnerItem=", partnerItem); console.log(`history.push(/detailpartner/${partnerItem.idpartner})`);history.push(`/detailpartner/${partnerItem.idpartner}`)}}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrowsePartnersView;